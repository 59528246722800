var React = require('react');
var TitleHeader = require('./../title-header.js');

var Info = React.createClass({displayName: "Info",
	render : function () {
		return (
			React.createElement("div", {className: "row info"}, 
				React.createElement("div", {className: "small-8 columns"}, 
				    React.createElement("h2", null, "Phone : +1 (305)-762-9121"), 
				    React.createElement("h2", null, "Fax : +1 (347)-710-7970"), 
				    React.createElement("h2", {className: "last"}, "Address : 4562 NW 2nd Terrance, Miami, Fl, 33126"), 
				    React.createElement(TitleHeader, {icon: "fa-globe", title: "Who we are", descrip: "Latinotrading.com short descript "}), 
				    React.createElement("img", {src: "images/logo.png", style: { float : 'left', marginRight : 10}}), 
				    React.createElement("p", null, "Latinotrading.com is an company provinding a Donec" + ' ' + 
				    "sed odio dui. Etiam porta sem malesuada magna mollis" + ' ' +
				     "euismod. Nullam id dolor id nibh ultricies vehicula" + ' ' +
				      "ut id elit. Morbi leo risus, porta ac consectetur" + ' ' + 
				      "ac, vestibulum at eros. Praesent commodo cursus" + ' ' + 
				      "magna. Lorem tiam porta sem malesuada magna mollis" + ' ' +
				     "euismod. Nullam id dolor id nibh ultricies vehicula" + ' ' +
				      "ut id elit. Morbi leo risus, porta ac consectetur" + ' ' + 
				      "ac, vestibulum at eros."
				     )
				)
		    )
			);
	}
});
module.exports = Info;