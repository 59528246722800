var React = require('react');

	Ellipsis = React.createClass({displayName: "Ellipsis",
		getInitialState : function () {
			return {
				open : false
			}
		},
		render : function () {
			var text = this.state.open ? this.props.text : this.props.text.substring(0,180);
			var spanStyle = {
				color: '#018A2F',
				fontWeight: 'bold',
				textDecoration: 'underline'
			}
			return (
				React.createElement("p", null, 
					text, 
					React.createElement("span", {onClick: function()   {return this.setState({ open : !this.state.open });}.bind(this), style: spanStyle}, 
					 this.state.open ? 'Less' : 'More'
					)
				)
			);
		}
	});

module.exports = Ellipsis;