var React = require('react/addons');  
var ReactCSSTransitionGroup = React.addons.CSSTransitionGroup;

var ImageOrbit = React.createClass({displayName: "ImageOrbit",
  propTypes: {
    imageSrc: React.PropTypes.string.isRequired
  },
  componentDidMount : function () {
  	
  },
  render: function() {
    var css = {
    	height          : '400px',
    	width    		: '100%',
    	position 		: 'absolute',
    	top 			: '0px',
    	left 			: '0px',
    	backgroundImage : 'url('+ this.props.imageSrc +')',
    	backgroundPosition : 'center',
    	backgroundSize	: 'cover',
    	//border : '1px solid #019934',
    	//borderRadius : '10px'
    }
    return (
      React.createElement("div", {className: "small-12 columns"}, 
        React.createElement(ReactCSSTransitionGroup, {transitionName: "orbit"}, 
       		React.createElement("div", {key: this.props.imageSrc, style: css, onTouchStart:  this._startTouch, onTouchEnd:  this._endTouch})
        )
      )
    );
  },
  _startTouch : function (e) {
  	this.touchX = e.touches[0].clientX;
  },
  _endTouch :function (e) {
  	if (this.touchX > e.changedTouches[0].clientX) this.props.moveCb('left');
  	else this.props.moveCb('right');
  }
});

var Orbit = React.createClass({displayName: "Orbit",
	getDefaultProps : function () {
		return {
			urls : ["images/orbit/image_1.jpg","images/orbit/image_2.jpg","images/orbit/image_3.jpg","images/orbit/image_4.jpg"]
		};
	},
	getInitialState : function () {
		return { index : 0, over : false };
	},
	componentDidMount : function () {
		this.myInterval = setInterval(function () {
			this.nextImg();
		}.bind(this),8000);
	},
	componentWillUnmount : function () {
		clearInterval(this.myInterval);
	},
	render : function () {
		var css = {
			position : 'relative',
			height : '400px',
			marginBottom : '20px'
		};
		var controlsCss = {
			display : this.state.over ? 'block' : 'none'
		};

		return (
			React.createElement("div", {className: "Orbit", style: css, onMouseEnter: this.mouseEnter, onMouseLeave: this.mouseLeave}, 
				React.createElement(ImageOrbit, {imageSrc: this.props.urls[this.state.index], moveCb:  this.moveCb}), 
				React.createElement("div", {className: "orbitControls", style: controlsCss}, 
		        	React.createElement("div", {className: "prev", onClick: this.prevImg}), 
		        	React.createElement("div", {className: "next", onClick: this.nextImg})
		        )	
	        )	
			);
	},
	nextImg : function () {
		this.setState({ index : this.state.index < this.props.urls.length - 1 ? this.state.index + 1 : 0 });
	},
	prevImg : function () {
		this.setState({ index : this.state.index > 0 ? this.state.index - 1  : this.props.urls.length - 1 });
	},
	mouseEnter : function () {
		this.setState({ over : true });
	},
	mouseLeave : function () {
		this.setState({ over : false });
	},
	moveCb : function (direction) {
		if (direction == 'right' ) this.prevImg();
		else this.nextImg();
	}
});
module.exports = Orbit;