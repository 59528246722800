var React = require('react');
var Input = require('./input.js');
var TextArea = require('./textarea.js');
var Recaptcha = require('react-recaptcha');
var $ = require('jquery');

var contactForm  = React.createClass({displayName: "contactForm",
	getInitialState : function () {
		return {
			message : ''
		}
	},
	render : function () {
		return (
			React.createElement("form", null, 
				React.createElement("h4", {className: "messageOK"}, this.state.message), 
				React.createElement(Input, {name: "First Name", placeholder: "First Name", ref: "first_name"}), 
				React.createElement(Input, {name: "Last Name", placeholder: "Last Name", ref: "last_name"}), 
				React.createElement(Input, {name: "Email", placeholder: "Email", type: "email", ref: "email"}), 
				React.createElement(Input, {name: "Phone", placeholder: "Phone", ref: "phone"}), 
				React.createElement(TextArea, {name: "Message", placeholder: "Message", ref: "message"}), 
				React.createElement("div", {className: "row"}, 
			    	React.createElement("div", {className: "small-10 columns"}, 
				        React.createElement("div", {className: "row"}, 
					        React.createElement("div", {className: "small-3 columns"}, 
					          React.createElement("label", {htmlFor: this.props.name, className: "right inline"}, this.props.name)
					        ), 
					        React.createElement("div", {className: "small-9 columns"}, 
					          	React.createElement("a", {href: "#", className: "button right success", onClick: this.handleSubmit}, "Submit")
					        )
				      	)
			    	)
		    	)
			)
			); 
	},
	handleSubmit : function (e) { 
		var data;
		e.preventDefault();
		this.setState({
			message : null
		});

		var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/ig;
		if (this.refs.email.getValue() == '' || !re.test(this.refs.email.getValue()))	{
		    alert('Please enter a valid email address.');
		    return false;
		}

		data = {
			first_name : this.refs.first_name.getValue() || 'Empty',
			last_name : this.refs.last_name.getValue() || 'Empty',
			email : this.refs.email.getValue(),
			phone : this.refs.phone.getValue() || 'Empty',
			message : this.refs.message.getValue() || 'Empty'
		};
		console.log('sending : ', data);
		$.ajax({
			url : '/contactmail.php',
			type : 'POST',
			data : data
		}).done(function (res) {
			console.log(res);
			this.setState({
				message : 'Your message has been sent, we will contact you shortly. Thank You.'
			});
		}.bind(this)).fail(function () {
			alert('Something went wrong, please try again.');
		});
	}
});
module.exports = contactForm;

/*

 <div className="row">
 <div className="small-10 columns">
 <div className="row">
 <div className="small-9 columns right">
 <h6>Hello</h6>
 <Recaptcha
 sitekey="6Lem_w4TAAAAAGbUOmp2hkj8b38iQkKmkt1Y__k2"
 />
 </div>
 </div>
 </div>
 </div>

* */