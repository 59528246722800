var React = require('react');
var TitlePage = require('./titlepage.js');
var Orbit = require('./orbit.js');
var PromoProducts = require('./promoproducts.js'); 

var HomePage = React.createClass({displayName: "HomePage",
 	render : function () {
 		return (
 			React.createElement("div", {className: "row"}, 
				React.createElement("h1", {className: "home-frase"}, 
						"... a company with a", 
			      React.createElement("img", {src: "images/greencrown.jpg", style: { width : 140, position : 'relative', top : -20}}), 
			      React.createElement("span", {style: { marginLeft : 5}}, "thumb.")
		    ), 
 				React.createElement(Orbit, null), 
 				React.createElement(PromoProducts, null)
 			)
 			);
 	}
});
module.exports = HomePage;