var $ = require('jquery');
var React = require('react');
var Router = require('react-router');       
var Route = Router.Route;
var DefaultRoute = Router.DefaultRoute;
var NotFoundRoute = Router.NotFoundRoute;   
var Redirect = Router.Redirect;
var RouteHandler = Router.RouteHandler; 
var _ = require('underscore');
var Backbone = require('backbone'); 
  
var TopBar = require('./topbar');
var AppFooter = require('./app-footer');

var Products = require('./products');
var Home = require('./homepage');
var ContactUs = require('./contact-us-page');
var Loader = require('./Loader');

var productCollection = Backbone.Collection.extend({
	url : 'http://latinocloud.herokuapp.com/public/products'
});
var products = new productCollection();

var App = React.createClass({displayName: "App",
	mixins: [Router.State],
	childContextTypes : {
		collection : React.PropTypes.object
	},
	getChildContext : function () {
		return {
			collection : products
		}
	},
	render : function () {
	    return (
	    	React.createElement("div", {onTouchStart: function()   {return console.log('touch');}}, 
				React.createElement(TopBar, {name: "Latinotrading LLC."}), 
				React.createElement(RouteHandler, null), 
				React.createElement(AppFooter, null)	 
			)	
	    );  	
	}
}); 

var routes = (
  	React.createElement(Route, {handler: App, path: "/", name: "home"}, 
    	React.createElement(DefaultRoute, {handler: Home}), 
    	React.createElement(Route, {handler: Products, path: "products", name: "products"}), 
    	React.createElement(Route, {handler: ContactUs, path: "contactus", name: "contactus"})
 	)
);
// <NotFoundRoute handler={UnderConst} />
$(document).ready(function () {
	React.initializeTouchEvents(true);
	React.render(React.createElement(Loader, null),document.getElementById('app'));
	products.fetch().done(function (data) {
		Router.run(routes, function (Handler) {
		  React.render(React.createElement(Handler, null), document.getElementById('app'));
		});
	});
	
});
    


