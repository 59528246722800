var React = require('react');
var Map = require('./map.js');  

var cssContainer = {
	margin : '0px 15px',
	height : '500px',
	backgroundColor : '#ccc'
}
var GoogleMap = React.createClass({displayName: "GoogleMap", 
	render : function () {
		return (
				React.createElement("div", {style: cssContainer, ref: "container"}, 
				    React.createElement(Map, {latitude: 26.158010, longitude: -98.274464, zoom: 8, width: 800, height: 500, 
  points: [{latitude:26.158010,longitude:-98.274464,title:"Lineage Logistic"},{latitude:26.8405,longitude:-98.151637,title:"Eagle Cold Storage"}]})
				)
			);
	}
});
module.exports = GoogleMap;