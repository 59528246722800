var React = require('react');

var Loader = React.createClass({displayName: "Loader",
	render : function () {
		var style = {
			fontSize : 180,
			color : '#019934',
			position: 'fixed',
  			top: '50%',
			marginTop: -100,
			left: '50%',
			marginLeft: -70,
			textAlign : 'center'
		};
		return (
			React.createElement("div", {className: "row"}, 
				React.createElement("div", {style: style}, 
					React.createElement("i", {className: "fa fa-refresh fa-spin"}), 
					React.createElement("h1", {style: { fontSize : 25, marginTop : 25, color : '#dedede'}}, "Loading data")
				)
			)
			);
	} 
});
module.exports = Loader;
 