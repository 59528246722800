var React = require('react');
var TitleHeader = require('./title-header.js');
var ProductList = require('./product-list.js');
var _ = require('underscore');

/* DATA */
var productsData = require('./../../../data/products.js');



var PromoProducts = React.createClass({displayName: "PromoProducts",
	contextTypes : {
		collection : React.PropTypes.object
	},
	render : function () {
 		var listFiltered = this.context.collection.where({ promo : true });
 		return (
 			React.createElement("div", {className: "row"}, 
 				React.createElement("div", {className: "large-12 columns"}, 
 					React.createElement(ProductList, {list: listFiltered})
 				)
 			)
 			);
	}
});
module.exports = PromoProducts;