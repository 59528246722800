var React = require('react');
var AppConfig = require('./app-variables.js');

var cssList = {
	listStyle : 'none'
};
var cssItem = { 
	display : 'inline',
	margin : '0px 5px',
	fontSize : '35px',
};
var cssA = {
	color: AppConfig.textMainColor
}
var Social =React.createClass({displayName: "Social",
	getDefaultProps : function () {
		return {
			facebook : '#',
			twitter  : '#',
			google   : 'https://plus.google.com/+LatinotradingLlcMiami/about',
			pinterest : 'http://www.pinterest.com/latinotrading'
		};
	},
	render : function () {
		return (	
			React.createElement("ul", {style: cssList}, 
				React.createElement("li", {style: cssItem}, 
					React.createElement("a", {href: this.props.facebook, target: "_black", style: cssA}, 
						React.createElement("i", {className: "fa fa-facebook-square"})
					)
				), 
				React.createElement("li", {style: cssItem}, 
					React.createElement("a", {href: this.props.linkedin, style: cssA, target: "_black"}, 
						React.createElement("i", {className: "fa fa-linkedin-square"})
					)
				), 
				React.createElement("li", {style: cssItem}, 
					React.createElement("a", {href: this.props.google, style: cssA, target: "_black"}, 
						React.createElement("i", {className: "fa fa-google-plus-square"})
					)
				), 
				React.createElement("li", {style: cssItem}, 
					React.createElement("a", {href: this.props.pinterest, style: { color : '#A3262D'}, target: "_black"}, 
						React.createElement("i", {className: "fa fa-pinterest-square"})
					)
				)
			)
			);
	}
}); 
module.exports = Social;