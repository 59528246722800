var React = require('react');
var variables = require('./app-variables.js');
var Ellipsis =  require('./Ellipsis');

var cssProduct = {
	textAlign : 'center',
	color : variables.textMainColor
};
var cssName = {
	color : variables.textMainColor  
}
var Product = React.createClass({displayName: "Product",
	render : function () {
		var styleImg = {
			height : 200
		};
		return (
			React.createElement("div", {style: cssProduct}, 
				React.createElement("img", {src:  this.props.data.get('images')[0], style: styleImg}), 
				React.createElement("h2", {style: cssName}, this.props.data.get('name')), 
				React.createElement(Ellipsis, {text: this.props.data.get('description')})
			)
			);
	}
});
module.exports = Product;